(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-overview-rows/assets/javascripts/coupon-overview-rows.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-overview-rows/assets/javascripts/coupon-overview-rows.js');
"use strict";


const {
  Coupon,
  useCouponContext
} = svs.components.tipsen.couponComponents;
const {
  DescriptionSize
} = svs.components.lbUi.participants.constants;
const getNameSize = rowOptions => {
  const numberOfSelectedOptions = rowOptions === null || rowOptions === void 0 ? void 0 : rowOptions.filter(option => option.isSelected).length;
  let nameSize = DescriptionSize.Full;
  if (numberOfSelectedOptions > 6) {
    nameSize = DescriptionSize.Medium;
  }
  return nameSize;
};
const CouponOverviewRows = _ref => {
  let {
    style = {}
  } = _ref;
  const {
    couponRows
  } = useCouponContext();
  return React.createElement("div", {
    className: "pg_coupon_overview_rows",
    style: style
  }, couponRows.map(row => React.createElement("div", {
    className: "pg_coupon_overview_rows__row",
    key: row.eventNumber
  }, React.createElement("div", null, row.eventNumber, ".\xA0"), React.createElement("div", {
    className: "pg_coupon_overview_rows__row__participants"
  }, React.createElement(Coupon.Participant, {
    nameSize: getNameSize(row.options),
    participant: row.homeParticipant
  }), "\xA0-\xA0", React.createElement(Coupon.Participant, {
    nameSize: getNameSize(row.options),
    participant: row.awayParticipant
  })), React.createElement(Coupon.OutcomeRow, {
    className: "pg_coupon_overview_rows__row__outcome_grid",
    outcomes: row.options,
    signDistribution: row.signDistribution,
    signLabel: row.mSign ? 'M' : ''
  }))));
};
setGlobal('svs.components.tipsen.couponOverviewRows.CouponOverviewRows', CouponOverviewRows);

 })(window);